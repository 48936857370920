
// import nonAthletesCard from './non-athletes-card.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {


      array: [],
      activities: [
        { id: 'yhvpDrSiqDPyD5UpjsUn', image: '/images/religious-activity.png', name: { ar: 'الأنشطة الدينية', en: 'Religious Activities' } },
        { id: 'yhvpDrSiqDPyD5Upjssd', image: '/images/cult.png', name: { ar: 'الأنشطة الثقافي', en: 'Cultural Activities' } },
        { id: 'yhvpDrSiqDPyD5Upjsdn', image: '/images/fun-activity.png', name: { ar: 'الأنشطة الترفيهية', en: 'Leisure Activities' } },
        { id: 'yhvpDrSiqDPyD5Upjsrr', image: '/images/sports-activity.png', name: { ar: 'الأنشطة الرياضية', en: 'Sports Activities' } },
      ]
    }
  },
  components: {
    // nonAthletesCard
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: "nonAthletes/rows",
      loading: "nonAthletes/loading",

    }),

    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    isDark() {
      return this.$q.isActive
    }
  },
  methods: {
    ...mapActions({
      doFetch: "nonAthletes/doFetch"

    }),
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en']
    }
  },
  async mounted() {
    console.log('screen width', screen.width);


    await this.doFetch()
    console.log(this.rows)

  }
}
